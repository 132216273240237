import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";

const NotFound = () => {
  return (
    <div>
      <Layout>
        <Head title="404" />
        <Link to="/">
          <h1>Oops, that doesn't exist</h1>
        </Link>
      </Layout>
    </div>
  );
};

export default NotFound;
